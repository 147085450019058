$theme-colors: (
  "primary": #417468,
  "danger": #ff4136
);
@import "node_modules/bootstrap/scss/bootstrap";
/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 *
 * Modified 11/2019 by Tasya
 */

/*Fix dropdown z index */
.dropdown-menu.show {
  z-index:10000;
}

/*Bootbox fixes for bootstrap 4*/
.bootbox .modal-header{
   flex-direction: row-reverse;
   /*display: block;*/
}
/*# sourceMappingURL=bootstrap.css.map */

/* Added by Tasya for red star for required fields*/
.form-group.required .control-label:after {
  content:"*";
  color:red;
}
.invalid {
  font-weight: bold;
  color:red;
}

.btn-arrow-right, .btn-arrow-left {
  position: relative;
  padding-left: 18px;
  padding-right: 18px;
}
.btn-arrow-right {
  padding-left: 36px;
}
.btn-arrow-left {
  padding-right: 36px;
}
.btn-arrow-right:before, .btn-arrow-right:after, .btn-arrow-left:before,
  .btn-arrow-left:after {
  /* make two squares (before and after), looking similar to the button */
  content: "";
  position: absolute;
  top: 5px; /* move it down because of rounded corners */
  width: 26px; /* same as height */
  height: 26px; /* button_outer_height / sqrt(2) */
  background: inherit; /* use parent background */
  border: inherit; /* use parent border */
  border-left-color: transparent; /* hide left border */
  border-bottom-color: transparent; /* hide bottom border */

}

.btn-arrow-right:before, .btn-arrow-right:after {
  transform: rotate(45deg);
  /* rotate right arrow squares 45 deg to point right */
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.btn-arrow-left:before, .btn-arrow-left:after {
  transform: rotate(225deg);
  /* rotate left arrow squares 225 deg to point left */
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
}

.btn-arrow-right:before, .btn-arrow-left:before {
  /* align the "before" square to the left */
  left: -12px;
}

.btn-arrow-right:after, .btn-arrow-left:after {
  /* align the "after" square to the right */
  right: -12px;
}

.btn-arrow-right:after, .btn-arrow-left:before {
  /* bring arrow pointers to front */
  z-index: 1;
}

.btn-arrow-right:before, .btn-arrow-left:after {
  /* hide arrow tails background */
  background-color: white;
}
/*This will give the appearance of a modal backdrop below the topmost visible modal. That way it grays out your lower modals underneath.*/
.modal-backdrop {
    visibility: hidden !important;
}
.modal:after { 
  content: ""; 
  display: block; 
  background: rgba(0,0,0, .5); 
  top: 0; 
  bottom: 0; 
  width: 100%; 
  z-index: -1; 
}


.modal-dialog{
    position: relative;
    display: table; /* This is important */ 
    overflow-y: auto;    
    overflow-x: auto;
    width: auto;
    min-width: 300px;   
}
/* make buttons switch to inline on small screens*/
@media (min-width: 767px) {
    .btn-md-block {
        display: block;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .btn-md-block {
        margin-right:0.5vh;
    }
}
/* height for map (otherwise leaflet won't show up) */
.map {
  min-height:40vh;
}
.link {
  cursor: pointer;
}
